import { FaYoutube, FaDiscord, FaMedium, FaTwitter } from "react-icons/fa";

const data = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/Ceazor7",
  },
  {
    icon: <FaDiscord />,
    url: "https://discord.gg/g7J9WYEV6K",
  },
  {
    icon: <FaYoutube />,
    url: "https://www.youtube.com/c/CeazorsSnackSandwich",
  },
  {
    icon: <FaMedium />,
    url: "https://ceazor.medium.com/",
  },
];

export default data;
